import AppRoutes from "../../AppRoutes/AppRoutes";


const Main = () => {

    return (
        <div>
            <AppRoutes/>
        </div>
    );
};

export default Main;
