import React from 'react';
import EmailForm from "../../EmailForm/EmailForm";

const MainPage = () => {
    return (
        <div>
            <EmailForm />
        </div>
    );
};

export default MainPage;
